<template>
  <BossCollapse></BossCollapse>
</template>

<style scoped>
</style>

<script>
  // components
  import BossCollapse from '@/components/share/BossCollapse.vue';

  export default {
    components: {
      BossCollapse,
    }
  }
</script>