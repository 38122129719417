<template>
  <div ref="footer" class="fixed-footer d-flex justify-content-around align-items-center footerInfo">
    <div>
      <span>合經投資股份有限公司</span>
    </div>
  </div>
</template>

<style scoped>
.w-33 {
  width: 33.3%;
}

.fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #42a2a1;
  color: #fff;
  padding: 5px;
  z-index: 2;
}

.footerInfo{
  font-size: 16px;
  padding: 10px 0;
}

@media (max-width: 767px) {
  .footerInfo{
    font-size: 14px;
  }
}
</style>

<script>
export default {
  name: 'BossFooter',
  props: {
  }
}
</script>