<template>
  <div class="pd-calc py-5">
    <div class="d-flex S_title fw-bolder">
      資訊安全政策
    </div>
    <hr class="opacity-100">
    <div class="content text-justify">
      <p>
        歡迎使用SUM.to老闆帳現金流估算財務管理工具（以下簡稱本網站），本網站及相關服務是由合經投資股份有限公司所提供。為了讓您能夠安心使用本網站及相關服務，並保障您的權益，特於此向您說明本網站的資訊安全政策。
      </p>
      <div>
        <div class="articleTitle">
          一、目的
        </div>
        <div>
          合經投資股份有限公司(以下簡稱本公司)為強化資訊安全管理，確保本公司資訊資產之機密性，完整性及可用性。以提供本公司業務持續運作所需之資訊環境與架構，並符合相關法規之要求，避免遭受內部，外部蓄意或意外事件影響，特制定此政策（以下簡稱本政策），作為本公司資訊安全管理系統(以下簡稱ISMS)的最高指導原則。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          二、目標
        </div>
        <div>
          本公司資訊安全目標為：確保從業務進件到交易完成，重要資訊及服務之機密性（Confidentiality）、完整性 （Integrity）、可用性（Availability）與遵循性（Compliance）。並依各階層與職能定義及量測資訊安全績效之量化指標，以確認ISMS實施狀況及是否達成資訊安全目標。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          三、適用範圍
        </div>
        <div>
          本公司考量內部及外部議題、關注方之需要及期望，以及本公司活動與其他組織活動間之介面及相依性，本政策及ISMS適用範圍為：SUM.to老闆帳現金流估算之軟體開發、營運及作業環境，包括：實體辦公室區域、雲端系統、開發人員、軟體、營運資料、系統管理單位及相關作業流程。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          四、涵蓋內容
        </div>
        <div>
          ISMS包括內容如下，有關單位及人員就下列事項，應訂定對應之管理規範或實施計畫，並據以實施及定期評估實施成效：
          <ol>
            <li>資安組織及管理審查程序</li>
            <li>文件與記錄管理</li>
            <li>資安目標與績效評量</li>
            <li>風險管理</li>
            <li>資訊安全內部稽核</li>
            <li>持續改善</li>
            <li>人力資源安全管理</li>
            <li>資產管理</li>
            <li>存取控制管理</li>
            <li>實體與環境安全管理</li>
            <li>運作安全與密碼學</li>
            <li>通訊安全管理</li>
            <li>系統獲取、發展與維護管理</li>
            <li>供應商關係管理</li>
            <li>資訊安全事故管理</li>
            <li>營運持續管理</li>
            <li>遵循性管理</li>
          </ol>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          五、組織與權責
        </div>
        <div>
          為確保ISMS能有效運作，應明定資訊安全組織及權責，以推動及維持各類管理、執行與查核等工作之進行。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          六、實施原則
        </div>
        <div>
          ISMS之實施應依據規劃（Plan）、執行（Do）、查核（Check）及改善（Act）流程模式，以週而復始、循序漸進的精神，確保ISMS運作之有效性及持續改善。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          七、審查與評估
        </div>
        <div>
          1. 本政策應於重大變更或至少每年評估審查一次，以反映相關法令法規、技術、業務及相關部門等最新發展現況，確保資訊安全作業之有效性。<br/>
          2. 本政策應依據審查結果進行修訂，並經本公司負責人簽核發佈後始生效。<br/>
          3. 本政策訂定或修訂後應以書面、電子郵件、文件管理系統或其他方式告知關注方，如：客戶、合作夥伴、所屬員工、供應商等。<br/>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .text-justify{
    text-align: justify;
  }
  .content > div {
    font-size: 15px;
    padding: 1rem;
    margin-bottom: 2rem;
    border: 5px solid #f7f6f6;
  }
</style>