<template>
  <div class="d-flex align-items-center pd-calc fixed-header fixed-shadow">
    <div class="w-50 py-3 d-flex justify-content-start">
      <a @click="goBannerStep">
        <img class="bossImage01" :src="image" alt="bossImg01">
      </a>
    </div>
    <!-- 上方選單內容 -->
    <div class="w-50 d-flex justify-content-evenly align-items-center top-menu">
      <div>
        <button class="btn fw-bold btn-hover menuText" @click="goSwiperStep">功能介紹</button>
      </div>
      <div>
        <button class="btn fw-bold btn-hover menuText" v-on:click="navigateTo('/TutorialGuide')">操作教學</button>
      </div>
      <div>
        <button class="btn fw-bold btn-hover menuText">
          <a href="https://sum.to/login.html">登入</a>
        </button>
      </div>
      <div>
        <button class="btn fw-bold btn-boss menuText">
          <a href="https://sum.to/signup">免費註冊</a>
        </button>
      </div>
    </div>
    <!-- 漢堡選單內容 -->
    <div class="w-50 d-flex justify-content-end hamburger-menu">    
      <div id="divHamburger" class="hamburger" ref="myHamburger" @click="toggleCustomClass">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <div class="slide-container fixed-div" :class="{ 'open': isVisible }" ref="myOpenBox">
    <transition name="slide">
      <ul class="navbar-nav ml-auto nav-right" data-easing="easeInOutExpo" data-speed="1250" data-offset="65">
        <li class="nav-item nav-custom-link">
          <a class="nav-link" href="javascript:void(0);" @click="goSwiperStep('Mobile')">功能介紹</a>
        </li>
        <li class="nav-item nav-custom-link">
          <a class="nav-link" href="javascript:void(0);" v-on:click="navigateTo('/TutorialGuide')" @click="toggleCustomClass">操作教學</a>
        </li>
        <li class="nav-item nav-custom-link">
          <a class="nav-link" href="https://sum.to/login.html" @click="toggleCustomClass">登入</a>
        </li>
        <li class="nav-item nav-custom-link btn btn-demo-small">
          <a class="nav-link" href="https://sum.to/signup" @click="toggleCustomClass">免費註冊 <i class="icon-mobile fa-solid fa-right-to-bracket"></i></a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style scoped>
  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 2;
  }

  .fixed-shadow {
    box-shadow: 0px 1px 7px rgb(58 133 132 / 7%);
  }

  .fixed-div {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 2;
  }

  a > .bossImage01 {
    cursor: pointer;
  }

  .bossImage01 {
    width: 120px;
    height: auto;
  }

  .btn-hover:hover {
    color: #42a2a1;
  }

  .btn-hover a {
    text-decoration: none;
    color: #212529;
  }

  .btn-hover a:hover {
    color: #42a2a1;
  }

  .btn-boss {
    --bs-btn-color: #fff;
    --bs-btn-bg: #42a2a1;
    --bs-btn-border-color: #1acbc8;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #048381;
    --bs-btn-hover-border-color: #25cff2;
    --bs-btn-focus-shadow-rgb: 11, 172, 204;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #1fafad;
    --bs-btn-active-border-color: #1fafad;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #42a2a1;
    --bs-btn-disabled-border-color: #42a2a1;
  }

  .btn-boss a {
    text-decoration: none;
    color: #fff;
  }

  .hamburger-menu {
    display: none !important;
  }

  .menuText {
    font-size: 14px;
    position: absolute;
  }

  #divHamburger {
    display: none;
    width: 48px;
    height: 42px;
    left: 200px;
    top: 21px;
    border-radius: 0 7px 7px 0;
  }

  .slide-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;
  }

  .slide-container.open {
    max-height: 500px; /* 設定您想要的高度 */
  }

  .slide-content {
    transition: transform 1s;
    transform: translateY(-100%);
  }

  .slide-content-enter-active {
    transform: translateY(0);
  }

  .slide-content-leave-active {
    transform: translateY(-100%);
  }

  @media (min-width: 993px) {
    .fixed-div {
      display: none;
    }
  }
  @media (max-width: 992px) {
    .top-menu {
      display: none !important;
    }

    .hamburger-menu {
      display: flex !important;
    }

    .bossImage01 {
      width: 100px;
    }

    .hamburger {
        display: block;
    }

    #divHamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: ease-in-out;
      cursor: pointer;
      z-index: 8;
    }
      
    #divHamburger span {
      display: block;
      position: absolute;
      height: 2px;
      width: 50%;
      background: #42a2a1;
      border-radius: 9px;
      opacity: 1;
      left: 6px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
      
    #divHamburger span:nth-child(1) {
      top: 10px;
    }
    
    #divHamburger span:nth-child(2),#divHamburger span:nth-child(3) {
      top: 17px;
    }
    
    #divHamburger span:nth-child(4) {
      top: 24px;
    }
    
    #divHamburger.open span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
    
    #divHamburger.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    
    #divHamburger.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    
    #divHamburger.open span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    .navbar-nav {
      width: 100%;
    }

    .nav-right {
      float: left;
    }

    .ml-auto, .mx-auto {
      margin-left: auto!important;
    }

    .navbar-nav {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-left: 0;
      margin-top: 20px;;
      margin-bottom: 0;
      list-style: none;
    }

  }
  @media (max-width: 767px) {
    .bossImage01 {
      width: 76px;
    }
  }

</style>

<script>
import { ref } from 'vue';
import eventBus from '@/utils/event-bus';

export default {
  name: 'BossHeader',
  props: {
    image: {
      type: String
    }
  },
  methods: {
    goBannerStep() {
      this.closeCustomClass();
      this.$router.push({ name: 'HomePage'});
      eventBus.emit('scrollToBanner');
    },
    goSwiperStep(val) {
      if(val === 'Mobile') this.toggleCustomClass();
      this.$router.push({ name: 'GoTarget', params: { target: 'scrollToSwiper' } });
      eventBus.emit('scrollToSwiper');
    }
  },
  setup() {
    const myHamburger = ref(null);
    const myOpenBox = ref(null);
    const isVisible = ref(false);

    const toggleCustomClass = () => {
      myHamburger.value.classList.toggle('open');
      isVisible.value = !isVisible.value;
    };

    const closeCustomClass = () => {
      myOpenBox.value.classList.remove('open');
      myHamburger.value.classList.remove('open');
      isVisible.value = false;
    };
    
    return {
      myHamburger,
      myOpenBox,
      isVisible,
      toggleCustomClass,
      closeCustomClass
    };
  }
}
</script>