<template>
  <div id="app">
    <!-- Header -->
    <BossHeader :image="bossImg01"></BossHeader>
    <!-- View -->
    <router-view></router-view>
    <!-- Footer -->
    <BossFooter></BossFooter>
  </div>
</template>

<script>
  // components
  import BossHeader from '@/components/share/BossHeader.vue';
  import BossFooter from '@/components/share/BossFooter.vue';
  // images
  import BossImage01 from '@/assets/images/boss_01.png';

  export default {
    name: 'App',
    created() {
      document.title = '老闆帳';
    },
    components: {
      BossHeader,
      BossFooter,
    },
    data() {
      return {
        bossImg01: BossImage01,
      };
    },
  }
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
