import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import TutorialGuide from '@/components/TutorialGuide.vue';
import PolicyPage from '@/components/PolicyPage.vue';

const routes = [
  { 
    path: '/:pathMatch(.*)*', 
    redirect: '/HomePage'
  }, // 將根路徑重新導向到 '/HomePage'
  { 
    path: '/HomePage', 
    name:'HomePage', 
    component: HomePage
  },
  {
    path: '/HomePage/:target',
    name:'GoTarget',
    component: HomePage
  },
  {
    path: '/TutorialGuide',
    component: TutorialGuide
  },
  {
    path: '/PolicyPage/:id',
    component: PolicyPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;