<template>
  <div class="pd-calc py-5">
    <div class="d-flex S_title fw-bolder">
      隱私權政策
    </div>
    <hr class="opacity-100">
    <div class="content text-left">
      <p>
        歡迎使用SUM.to老闆帳現金流估算財務管理工具（以下簡稱本網站），本網站及相關服務是由合經投資股份有限公司所提供。本網站為了尊重並保護會員的隱私權，且遵循「個人資料保護法」之規定，因此制訂了隱私權保護政策(以下稱「本政策」)，幫助您了解本公司如何蒐集、利用及保護您所提供的個人資訊，下列隱私權保護政策的內容，請您參考。
      </p>
      <p>
        在您同意使用本網站及相關服務之前，煩請撥冗閱讀本隱私權政策，同意後再使用本網站及相關服務。當註冊用戶完成本網站之註冊用戶註冊手續、或開始使用本服務時，即表示已閱讀、瞭解並同意接受本服務以下條款之所有內容，並完全接受本服務現有與未來衍生的服務項目及內容，若尚未閱讀、瞭解並同意者，請不要使用本網站。
      </p>
      <div>
        <div class="articleTitle">
          一、適用範圍
        </div>
        <div>
          本政策適用於您使用「SUM.to老闆帳」網站所提供的各項服務時，有關個人資料的蒐集、利用及保護，但不適用於與「SUM.to老闆帳」網站連結之其他各網站。凡經由「SUM.to老闆帳」網站連結的其他網站，均有其專屬的隱私權保護政策，本網站並不負任何連帶責任。當您連結到這些網站時，關於個人資料的保護，應適用各該網站的隱私權保護政策。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          二、個人資料類別
        </div>
        <div>
          本網站內蒐集的個人資料之類別（依據法務部公告之「個人資料保護法之特定目的及個人資料之類別」詳列如下，代號及項目嗣後經法務部公告變更，亦隨同變更之）包括：，包含但不限於如下之法定個人資料類別：
          <br/>
          <br/>
          C001辨識個人者、C002辨識財務者、C003政府資料中之辨識者、C011個人描述、C021家庭情形、C023家庭其他成員之細節、C024其他社會關係、C031住家及設施、C032財產、C037慈善機構或其他團體之會員資格、C038職業、C053職業團體會員資格、C061現行之受雇情形、C062僱用經過、C063離職經過、C065工作、差勤紀錄、C068薪資與預扣款、C070工作管理之細節、C081收入、所得、資產與投資、C082負債與支出、C083信用評等、C084貸款、C085外匯交易紀錄、C086票據信用、C087津貼、福利、贈款、C088保險細節、C089社會保險給付、就養給付及其他退休給付、C091資料主體所取得之財貨或服務、C092資料主體提供之財貨或服務、C093財務交易、C094賠償、C101資料主體之商業活動、C102約定或契約、等。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          三、個人資料蒐集之特定目的
        </div>
        <div>
          本網站蒐集您提供之個人資料之特定目的（依據法務部公告之「個人資（依據法務部公告之「個人資料保護法之特定目的及個人資料之類別」詳列如下，代號及項目嗣後經法務部公告變更，亦隨同變更之）包括：
          <br/>
          <br/>
          001 人身保險、002人事管理、008中小企業及其他產業之輔導、022外匯業務、031全民健康保險、勞工保險、農民保險、國民年金保險或其他社會保險、036存款與匯款、040行銷、044投資管理、049宗教、非營利組織業務、052法人或團體對股東、會員（含股東、會員指派之代表）、董事、監察人、理事、監事或其他成員名冊之內部管理、063非公務機關依法定義務所進行個人資料之蒐集處理及利用、065保險經紀、代理、公證業務、、067信用卡、現金卡、轉帳卡或電子票證業務、068信託業務、081個人資料之合法交易業務、082借款戶與存款戶存借作業綜合管理、088核貸與授信業務、090消費者、客戶管理與服務、093財產保險、094財產管理、095財稅行政、098商業與技術資訊、103專門職業及技術人員之管理、懲戒與救濟、104帳務管理及債權交易業務、106授信業務、107採購與供應管理、111票券業務、112票據交換業務、120稅務行政、129會計與相關服務、135資(通)訊服務、136資(通)訊與資料庫管理、137資通安全與管理、145僱用與服務管理、148網路購物及其他電子商務服務、151審計、監察調查及其他監察業務、152廣告或商業行為管理、157調查、統計與研究分析、159學術研究、160憑證業務管理、166證券、期貨、證券投資信託及顧問相關業務、176其他自然人基於正當性目的所進行個人資料之蒐集處理及利用、181其他經營合於營業登記項目或組織章程所定之業務、182其他諮詢與顧問服務。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          四、個人資料處理及利用
        </div>
        <div>
          本網站針對所蒐集之個人資料，僅在蒐集之特定目的及相關法令規定之範圍內為之。除本政策另有規定或有下列情形或符合個資法之規定外，本公司絕不會將您的個人資料揭露於第三人、或移作其他目的使用：
          <br/>
          （一）配合司法單位之調查；<br/>
          （二）配合相關政府機關依法律或職務需要之調查；<br/>
          （三）取得您的同意；<br/>
          （四）配合法律規定；<br/>
          （五）內部分析與研究用途；<br/>
          （六）因涉及委外業務而有必要時。<br/>
          <br/>
          網站所登錄或留存之個人資料，您同意本公司在為提供本服務行銷、市場分析、統計或研究之目的範圍內，得記錄、保存及利用您在本網站所留存或產生的資料及紀錄，或提供本公司、關係企業及業務合作夥伴的行銷活動及相關優惠訊息給您。<br/>
          <br/>
          除非另有約定，基於客戶管理及資料保全，本公司得將您提供之資訊保存至本公司消滅為止。個人資料利用期間、地區、對象及方式：<br/>
          A. 期間：本公司將持續使用您提供的個人資料直至您提出停止利用或本公司終止提供服務之日為止。<br/>
          B. 地區：中華民國境內、境外（主管機關禁止者不在此限）。<br/>
          C. 對象：本公司、受本公司委託或提供本服務必要範圍內之關係企業及合作廠商。<br/>
          D. 方式：符合蒐集特定目的及法令規定範圍之利用。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          五、資料之保護
        </div>
        <div>
          本公司會將您的個人資料完整儲存於我們的資料儲存系統中，並以嚴密的保護措施防止未經授權人員之接觸。本公司的人員均接受過完整之資訊保密教育，相關處理人員皆簽有保密合約，如有違反保密義務者，將受相關法律及公司內部規定之處分。<br/>
          為了保護您個人資料之完整及安全，保存您個人資料之資料處理系統均已設有各項資訊安全設備及必要的安全防護措施，以保障您的個人資料不會被不當取得或破壞。 如因業務需要有必要委託第三者提供服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          六、其他自我保護措施
        </div>
        <div>
          會員應妥善保密自己的網路密碼及個人資料，不要將任何個人資料，尤其是網路密碼提供給任何人。在使用完「SUM.to老闆帳」網站所提供的各項服務功能後，務必記得登出帳戶，若是與他人共享電腦或使用公共電腦，切記要關閉瀏覽器視窗。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          七、帳號或密碼被冒用之處理
        </div>
        <div>
          當您知悉帳號、密碼或金融資訊等個人資料遭人盜用時，請盡速通知本網站服務人員，本公司有權暫停該帳號所生交易之處理及後續利用，並與您協商後續的處理方案。但前述因應措施不得解釋為本公司因此承諾或暗示對註冊用戶負有任何形式之補償或賠償責任。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          八、網站對外的相關連結
        </div>
        <div>
          您可透過本網站所提供的連結，點選進入其他網站，但這些連結網站的隱私權政策與本網頁之隱私權政策可能不同，本隱私權政策除本網站及相關服務、線上客服系統外，並不適用於其他連結網站，建議您自行參考並仔細閱讀該連結網站中的隱私權政策。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          九、Cookies
        </div>
        <div>
          為了便利註冊用戶使用，「SUM.to老闆帳」網站會使用cookie技術，以便於提供會員所需要的服務；cookie是網站伺服器用來和會員瀏覽器進行溝通的一種技術，它可能在會員的電腦中隨機儲存字串，用以辨識區別註冊用戶，若會員關閉cookie有可能導致無法順利登入網站或無法使用書籤、購物車等狀況。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          十、擔保責任
        </div>
        <div>
          您應擔保輸入及存放於本公司服務之資料倘涉及第三人之個資時，已確實依照個資法相關規定蒐集、處理、利用。如有違反導致本公司及營運者、所屬人員或其他第三人受有任何損害，均應負責賠償。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          十一、資料當事人權益
        </div>
        <div>
          本網站所蒐集個人資料之當事人，依個人資料保護法得對本公司行使以下權利：<br/>
          A. 查詢或請求閱覽。<br/>
          B. 請求製給複製本。<br/>
          C. 請求補充或更正。<br/>
          D. 請求停止蒐集、處理或利用。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          十二、隱私權保護政策之修正
        </div>
        <div>
          隨著市場環境的改變本公司將會不時修訂網站政策，並即時更新於本網站，註冊用戶應隨時注意本條款及本網站之公告，如您於本政策變更後持續使用本服務，視為您已閱讀瞭解並同意本政策之修改內容。如您對上述內容或本網站有任何不明瞭之處或意見，請與服務人員聯繫。
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .text-left {
    text-align: left;
  }
  .content > div {
    font-size: 15px;
    padding: 1rem;
    margin-bottom: 2rem;
    border: 5px solid #f7f6f6;
  }
</style>