<template>
  <div class="pd-calc py-5">
    <div class="d-flex S_title fw-bolder">
      使用者服務條款
    </div>
    <hr class="opacity-100">
    <div class="content text-left">
      <p>
        歡迎使用SUM.to老闆帳現金流估算財務管理工具（以下簡稱本網站），本網站及相關服務是由合經投資股份有限公司所提供。操作簡單容易上手的使用介面，讓SUM.to老闆帳成為新創及小微企業老闆們的財務小秘書，即時掌握應收/ 應付帳款的進度，協助老闆們管理應收/ 應付帳款，計算出合適的營運周轉時間與現金流，優化企業本身財務體質，為迎來企業新的成長打好基石。
      </p>
      <p>
        企業或商號（以下簡稱註冊用戶）均得於本網站申請註冊使用者帳號，每位用戶也可以於其使用者帳號內新增其他同仁（如財務主管等）登入系統，共同掌握即時的應收/ 應付款項資訊。
      </p>
      <p>
        本使用者條款係針對使用SUM.to老闆帳服務之使用者所簽訂之合約。當使用者完成本網站之使用者註冊手續或開始使用本服務時，即表示已閱讀、瞭解並同意接受本服務以下條款之所有內容，並完全接受本服務現有與未來衍生的服務項目及內容，若尚未閱讀、瞭解並同意者，請不要使用本網站。若您於修改或變更後繼續使用本服務，視為您已閱讀、瞭解並同意接受該等修改或變更。為確保您的權益，建議您經常確認本條款之最新條文內容。
      </p>
      <div>
        <div class="articleTitle">
          壹、使用者註冊及帳號維護
        </div>
        <div>
          （一）本網站服務物件僅向符合法律規定具有完全行為能力，能夠獨立承擔法律責任且能提供法律規定之有效身分證件的使用者。當使用者完成本網站之使用者註冊手續、或開始使用本服務時，即表示已閱讀、瞭解並同意接受本網站之所有條款內容。<br/>
          <br/>
          （二）本網站目前以「e-mail」方式註冊帳號，於註冊時請依本網站指示確實填寫，並應自行維護、更新相關帳號內容以及通訊地址等基本資料，如有不實或因未更新，導致後續產生法律問題或無法順利使用本網站之服務，使用者應自行承擔相關責任。<br/>
          <br/>
          （三）您同意並保證您所提供之個人資料真實、即時、有效、正確、適法及完整，且本網站於必要時並得要求您提供相關證明文件（包含但不限於公司變更登記事項表、負責人身分證明文件或公司大小章證明等文件）。<br/>
          <br/>
          （四）有下列情形之一者，本網站得拒絕使用者之註冊申請；本網站得暫停或終止您的用戶帳戶、並拒絕您使用本網站服務的部份或全部功能：<br/>
          1. 未依照本網站之規定如實填寫基本資料或填寫有誤者；<br/>
          2. 冒用他人名義進行申請者；<br/>
          3. 不具完全行為能力者；<br/>
          4. 未依本網站之規定提供身分證明文件者；<br/>
          5. 其他經本網站認定不符合申請條件的使用者。<br/>
          <br/>
          （五）若因您未及時更新基本資料，導致本服務無法提供或提供時發生任何錯誤，本網站亦不承擔任何責任，您將承擔因此產生的一切後果。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          貳、服務內容與收費
        </div>
        <div>
          本網站提供平台予使用者進行雲端財務管理，關於服務內容與收費方式，均參照本網站最新公告為準。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          叁、註冊注意事項
        </div>
        <div>
          每位註冊用戶可以於其使用者帳號內新增其他同仁（如財務主管等）登入系統（惟新增之同仁仍需另行於本網站註冊帳號，並由用戶於本網站完成授權），共同掌握即時的應收/ 應付款項資訊。用戶應自行監督帳戶內所有成員之使用行為，並承擔相關成員使用行為之法律效果及責任。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          肆、資料蒐集及隱私權保護及授權
        </div>
        <div>
          本網站對於您所提供、本網站自行收集、經認證的資訊將按照本條款、本網站隱私權政策及個人資料保護法予以保護或使用。您同意本網站可使用關於您的資料（包括但不限於本網站持有的有關您的目前以及過去的檔案中的資料）並執行本網站的服務協定。倘若不涉及個人資料保護之其他資訊，本網站均得於不揭露來源之前提下用於統計、行銷之用途。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          伍、網站中立聲明
        </div>
        <div>
          本網站係提供註冊用戶進行雲端帳務管理服務之中立平台，並不針對註冊用戶者所提供、輸入或上傳之內容進行實質審查，註冊用戶應擔保所取得及輸入之資料並無違反中華民國法令及侵害他人權利之情事，亦不得非法利用本網站及相關服務。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          陸、免責條款
        </div>
        <div>
          於符合中華民國法令、本使用者條款及隱私權條款之範圍內，註冊用戶得自由使用本網站及相關服務。倘有違反法令或本網站所公布之條款，或有不正確操作、提供錯誤資料、侵害他人權益、出於惡意或損害他人意圖等違法情事，均與本網站及本網站之所屬人員（包含但不限於董事、監察人、經理人、受任人或受僱人）無關，衍生之風險及法律責任，或因而造成本網站及所屬人員或其他第三人之損害，均應由註冊用戶自行承擔及負責賠償。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          柒、使用者資料備份及服務中斷責任
        </div>
        <div>
          （一）本網站不負擔平台因軟體或硬體問題所生之服務中斷責任，並保留於不經事前通知之前提下，得隨時終止支援任何硬體或軟體之權利。本網站有權隨時進行服務內容與介面更新，煩請註冊用戶隨時為資料備份，以避免資料毀損、遺失，倘因註冊用戶未即時備份導致資料毀損、滅失等情事，本網站不負相關損害賠償之責。<br/>
          <br/>
          （二）使用者帳號提供、輸入、上傳或儲存於本網站或使用本網站及相關服務所產生之一切資料，於註冊用戶終止本服務後，本網站仍有權持續保留五年，但此並非承諾本網站負有保存所有終止服務之使用者帳號之部分或全部資料之責任。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          捌、帳號密碼遭冒用或冒用疑慮之處理
        </div>
        <div>
          本網站承諾將依據法令及本網站公告之資訊安全政策，確保本網站相關服務之資訊安全，您瞭解且同意即使本網站按前開標準執行，仍無法保證您於接受服務時將不會產生任何資訊安全風險，故願免除本網站對於非可歸責之資安事件的法律責任。若本網站知悉使用者帳號遭冒用或有冒用之虞時，除將會適時向註冊用戶反應外，並得暫停該使用者帳號之使用權限，無需事先通知，且。但註冊用戶若可提出足夠之證據證明該使用者帳號並無冒用之情事，經本網站審查酌情確定無誤或經司法判決確立後，得回復該使用者帳號之權限。
        </div>
      </div>
      <div>
        <div class="articleTitle">
          玖、智慧財產權之保護與實務
        </div>
        <div>
          （一）本網站使用之-包含但不限於品牌、設計、商標、Logo、文字、圖片影像、檔案、資訊、產品描述、產品圖照、網頁架構與編排、相關程式碼等-所有無實體構成元素之智慧財產權均為本網站所有，於取得本網站事前書面同意前，不得採任何形式使用、重製、修改、編輯、散布、傳輸、或以其為基礎產生衍生作品。<br/>
          （二）註冊用戶應擔保於註冊時所使用之身份及所提供之相關資訊並無侵害他人權益，若有其他第三人主張有侵權之情事，均與本網站無涉，註冊用戶應自行承擔一切法律責任，本網站於知悉或收受第三人主張有侵權情事之通知後，得暫停該使用者帳號之使用權限，無需事先通知。但註冊用戶若可提出足夠證據證明並無侵權之情事，經本網站審查確定無誤或經司法判決確立後，得回復該帳號之權限。<br/>
        </div>
      </div>
      <div>
        <div class="articleTitle">
          拾、其他
        </div>
        <div>
          （一）註冊用戶若有違反中華民國法令或本使用者條款之情事，本網站有權隨時終止該使用者使用本網站及相關服務之權利，亦得刪除帳號或帳戶，無需事先通知。<br/>
          <br/>
          （二）註冊用戶若因違反中華民國法令或本網站各項條款之情事，導致他人受有損害者，應自行承擔損害賠償之責；若因註冊用戶之違法行為導致本網站商譽或其他權利受損，本網站有權要求賠償或為其他必要之處置。<br/>
          <br/>
          （三）本條款之解釋、補充與適用以中華民國法律（不含涉外民事法律適用法或其他類似法規）為準據法，如因本服務涉訟，並以臺灣臺北地方法院為第一審管轄法院。<br/>
          <br/>
          （四）本網站及合經投資股份有限公司對於註冊用戶因使用本服務所衍生之賠償責任，以損害發生時，該用戶就本服務所給付之全部服務費用為上限。<br/>
          <br/>
          （五）本條款任何一部若有無效之情況，不影響其他部份之效力。<br/>
          <br/>
          （六）本網站有權不定期修改本條款，並即時更新於本網站，使用者應隨時注意本條款及本網站之公告，如您於本條款變更後持續使用本服務，視為您已閱讀瞭解並同意本條款之修改內容。如您對上述內容或本網站有任何不明瞭之處或意見，請與服務人員聯繫。<br/>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .text-left {
    text-align: left;
  }
  .content > div {
    font-size: 15px;
    padding: 1rem;
    margin-bottom: 2rem;
    border: 5px solid #f7f6f6;
  }
</style>