<template>
  <div class="box d-flex flex-row justify-content-evenly align-items-center">
    <div>
      <img class="bossIcon" :src="image" alt="bossIcon">
    </div>
    <div class="min-text">
      <div>
        <h3 class="boxTitle">{{title}}</h3>
      </div>
      <div><span>{{desc01}}</span></div>
      <div><span>{{desc02}}</span></div>
    </div>
  </div>
</template>

<style scoped>
.box {
  background-color: #fcb042;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  height: 115px;
  color: #fff;
  margin: 1rem;
  min-width: 300px;
  max-width: 500px;
}

.min-text {
  min-width: 160px;
}

.bossIcon {
  width: 50px;
  height: 50px;
}

.boxTitle{
  font-size: 19px;
}

@media (max-width: 767px) {
  .box {
    width: 55%;
    height: 110px;
  }

  .box .boxTitle{
    font-size: 17px;
  }

  .box span{
    font-size: 14px;
  }
}
</style>

<script>
export default {
  name: 'BossBox',
  props: {
    image: {
      type: String
    },
    title: String,
    desc01: String,
    desc02: String
  },
}
</script>