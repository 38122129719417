<template>
  <div>
    <!-- Banner -->
    <div ref="bannerStep" class="bannerImage">
      <div class="w-100 d-flex flex-lg-row flex-column align-items-center pd-calc pd-banner">
        <div class="w-50"></div>
        <div class="w-50 d-flex flex-column justify-content-end align-items-center">
          <div class="align-self-center">
            <img class="mb-3 bossImage02" :src="bossImg02" alt="bossImg02">
          </div>
          <div class="d-flex flex-column justify-content-evenly fs-6 bossDiv">
            <div>
              <h3 class="fontTitle" style="font-weight:300">會計都看會計帳</h3>
              <h3 class="fontTitle" style="font-weight:500">老闆請用老闆帳</h3>
            </div>
            <div>
              <span style="font-weight:500">協助老闆管理應收應付帳款</span>
            </div>
            <div>
              <button class="btn btn-light fs-5 fw-bold bannerBtn" v-on:click="goLink()">免費註冊</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 輪播 -->
    <div ref="swiperStep" class="py-5">
      <Swiper :navigation="true" :loop="true" :autoplay="true" :autoplayTimeout="3000">
        <template v-for="(slide, index) in slides" :key="index">
          <SwiperSlide class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container">
            <img :src="slide.image" alt="圖片">
            <div class="d-flex flex-column align-items-center image-text">
              <div>{{ slide.text01 }}</div>
              <div>{{ slide.text02 }}</div>
              <div>{{ slide.text03 }}</div>
              <div>{{ slide.text04 }}</div>
            </div>
          </SwiperSlide>
        </template>
        <template #next>
          <div class="swiper-button-next" @click="slideNext"></div>
        </template>
        <template #prev>
          <div class="swiper-button-prev" @click="slidePrev"></div>
        </template>
      </Swiper>
    </div>
    <!-- 區塊 -->
    <div class="mb-100 pd-calc">
      <div class="d-flex flex-lg-row flex-column justify-content-evenly align-items-center">
        <BossBox :image="bossIcon01" title="多人管理帳號" desc01="公司同仁" desc02="一同掌握款項收支情況"></BossBox>
        <BossBox :image="bossIcon02" title="建置廠商名錄" desc01="建立常用" desc02="應收應付廠商資料"></BossBox>
      </div>
      <div class="d-flex flex-lg-row flex-column justify-content-evenly align-items-center">
        <BossBox :image="bossIcon03" title="廠商應收帳款轉入" desc01="登打請款資料、上傳發票" desc02="轉為公司應付帳款"></BossBox>
        <BossBox :image="bossIcon04" title="雲端平台" desc01="無論何時何地" desc02="都可掌握公司金流狀況"></BossBox>
      </div>
    </div>
    <!-- Policy -->
    <div class="mb-45 pd-calc bg-light d-flex flex-lg-row flex-column justify-content-center align-items-center fs-6">
      <div class="w-100 w-lg-50 d-flex justify-content-center my-2" style="font-size: 15px;">
        <span class="text-secondary btmMenu">© 2023 合經投資股份有限公司 All Rights Reserved</span>
      </div>
      <div class="w-100 w-lg-50 d-flex justify-content-around text-secondary">
        <a class="nav-link btmMenu" href="javascript:void(0);" v-on:click="navigateTo('/PolicyPage/Security'), scrollIntoBanner()">資訊安全政策</a>
        <span class="line"></span>
        <a class="nav-link btmMenu" href="javascript:void(0);" v-on:click="navigateTo('/PolicyPage/Privacy'), scrollIntoBanner()">隱私權政策</a>
        <span class="line"></span>
        <a class="nav-link btmMenu" href="javascript:void(0);" v-on:click="navigateTo('/PolicyPage/Terms'), scrollIntoBanner()">使用者服務條款</a>
      </div>
    </div>
    <!-- 廣告 -->
    <div class="fixed-box" v-if="fetchedImage">
      <a :href="fetchedLink" target="_blank">
        <img :src="fetchedImage" alt="Image from API">
      </a>
    </div>
  </div>
</template>

<style scoped>

.bannerImage {
  width: 100%;
  height: 550px;
  background-image: url('@/assets/images/banner.jpg');
  background-size: cover;
  background-position: center;
}

.bossImage02 {
  width: 200px;
}

.bossDiv {
  border: 1px solid #fff;
  height: 250px;
  padding: 20px 36px;
}

.bossDiv h3 , span {
  color: #fff;
}

.bossDiv button {
  color: #42a2a2;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-45 {
  margin-bottom: 45px;
}

.pd-banner {
  height: 550px;
}

.bannerBtn{
  padding: 3px 10px;
}

.image-container img {
  width: 45%;
}

.image-container div {
  width: 45%;
  height: 100%;
}

.image-text div {
  width: 100%;
  color: #999;
  font-size: 16px;
  line-height: 1.5rem;
}

.image-text div:first-of-type {
  color: #000;
  font-size: 20px;
  padding: 1rem 0;
}

.pdt-carousel {
  padding-top: 5rem;
}

.fixed-box {
  position: fixed;
  bottom: 4rem;
  right: 20px;
  width:30%;
  max-width:200px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
}

.fixed-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 992px) {
  .pd-banner {
    position: relative;
  }

  .bossDiv {
    padding: 25px 0;
    min-width: 250px;
  }
  .bossImage02 {
    width: 150px;
  }

  .bannerImage {
    width: 100%;
    height: 362px;
    margin-top: 0;
  }

  .pd-banner {
    height: 362px;
  }
  
  .fontTitle{
    font-size: 21px;
  }

  .bossDiv {
    height: 180px;
  }

  .bannerBtn{
    padding: 2px 6px;
    margin-top: 8px;
    font-size: 16px !important;
  }
}

.line {
  position: relative;
  width: 0;
  height: 20px;
}

.line::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: #6e777e;
  transform: translateX(-50%);
}


@media (max-width: 991px) {
  .pd-banner {
    top: 12%;
  }

  .image-text div:first-of-type {
    padding: 2rem 0 0.5rem 0;
  }
}

.line::before {
  height: 90%;
}

@media (max-width: 767px) {
  .image-container img {
    width: 70%;
  }
  .image-text div {
    font-size: 14px;
  }

  .image-text div:first-of-type {
    font-size: 17px;
  }
}

</style>

<script>
// Swiper
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

// axios
import axios from 'axios';

// eventBus
import eventBus from '@/utils/event-bus';

import BossBox from '@/components/share/BossBox.vue';

import Slide01 from '@/assets/images/slide01.jpg';
import Slide02 from '@/assets/images/slide02.jpg';
import Slide03 from '@/assets/images/slide03.jpg';
import Slide04 from '@/assets/images/slide04.jpg';

import BossImage02 from '@/assets/images/boss_02.png';
import BossCarousel01 from '@/assets/images/carousel_01.jpg';
import BossIcon01 from '@/assets/images/icon_01.png';
import BossIcon02 from '@/assets/images/icon_02.png';
import BossIcon03 from '@/assets/images/icon_03.png';
import BossIcon04 from '@/assets/images/icon_04.png';
import '@/assets/css/common.css';

// 引入所需的 Swiper 模組
SwiperCore.use([Navigation]);

export default {
  name: 'HomePage',
  props: {
  },
  components: {
    Swiper,
    SwiperSlide,
    BossBox,
  },
  mounted() {
    const target = this.$route.params.target;
    if (target === 'scrollToSwiper') {
      this.scrollIntoSwiper();
    }else {
      this.scrollIntoBanner();
    }
    eventBus.on('scrollToBanner', this.scrollIntoBanner);
    eventBus.on('scrollToSwiper', this.scrollIntoSwiper);
    // 獲取圖片, 連結
    this.fetchImage();
  },
  beforeUnmount() {
    eventBus.off('scrollToBanner', this.scrollIntoBanner);
    eventBus.off('scrollToSwiper', this.scrollIntoSwiper);
  },
  methods: {
    slideNext() {
      this.$refs.swiper.swiper.slideNext();
    },
    slidePrev() {
      this.$refs.swiper.swiper.slidePrev();
    },
    scrollIntoBanner() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollIntoSwiper() {
      const elementSwiper = this.$refs.swiperStep;
      const scrollY = elementSwiper.getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({ top: scrollY, behavior: 'smooth' });
    },
    goLink() {
      window.location.href = 'https://sum.to/signup';
    },
    async fetchImage() {
      try {
        const response = await axios.get('https://sum.to/ajax/promote_img');
        this.fetchedImage = response.data[0].img;
        this.fetchedLink = response.data[0].link;
        console.log(this.fetchedImage)
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  },
  data() {
    return {
      // 例如：autoplay, loop, pagination 等
      swiperOptions: {
        //每次滑動顯示的圖片數量為1
        slidesPerView: 1, 
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
      slides: [
        { image: Slide01, text01: "營運週轉金估算", text02: "提供系統公式", text03: "協助企業估算營運週轉金",  text04: "讓老闆掌控資金的安全水位"},
        { image: Slide02, text01: "上傳發票 mail通知付款", text02: "廠商線上填寫請款資料", text03: "自動帶入應付帳款", text04: "輕鬆控制付款時間"},
        { image: Slide03, text01: "預估現金流量表", text02: "整合公司應收、應付資料", text03: "預估未來現金水位", text04: "" },
        { image: Slide04, text01: "每日LINE提醒應收應付項目", text02: "掌握應收應付帳款", text03: "每日即時通知不漏接", text04: "" },
      ],
      bossImg02: BossImage02,
      bossCarouse01: BossCarousel01,
      bossIcon01: BossIcon01,
      bossIcon02: BossIcon02,
      bossIcon03: BossIcon03,
      bossIcon04: BossIcon04,
      // 初始值
      fetchedImage: null,
      fetchedLink: null,
    };
  },
}
</script>