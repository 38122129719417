import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import globalMixin from './mixin/globalMixin';

// 導入Element-ui樣式文件
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';

// 導入Bootstrap樣式文件
import 'bootstrap/dist/css/bootstrap.css'

const app = createApp(App);
// 使用 mixin
app.mixin(globalMixin);
app.use(ElementPlus);
app.use(router);
app.mount('#app');