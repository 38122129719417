<template>
  <div class="pt-5 pd-calc">
    <el-collapse v-model="activeNames">
      <el-collapse-item title="如何新增其他同仁登入系統？" name="1" style="font-size: 30ox;">
        <div class="manualFrame">
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>1. 進入「登入帳號」功能</p>
                  <p>2. 按「＋」開啟新增資料視窗</p>
                  <p>3. 輸入登入的姓名/E-mail/密碼後，送出新增</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse01" alt="圖片">
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="廠商如何通知我付款" name="2" style="font-size: 30ox;">
        <div class="manualFrame">
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>1. 進入「廠商/客戶資料」功能</p>
                  <p>2. 點擊該廠商的「上傳發票連結」</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0201" alt="圖片">
          </div>
            <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container addLine">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>3. 系統將開啟該廠商的「專屬」上傳視窗</p>
                  <p>4. 廠商即可透過此連結上傳發票等應收資料</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0202" alt="圖片">
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="廠商通知付款資料匯入應付報表" name="3" style="font-size: 30ox;">
        <div class="manualFrame">
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>1. 進入「應付通知」功能</p>
                  <p>2. 選擇欲匯入的應付帳款，按「…」開啟詳細資料</p>
                  <p>3. 確認資料無誤，按下「轉為應付資料」，即可將此筆資料導入應付報表</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse03" alt="圖片">
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="如何輸入應收應付資料？" name="4" style="font-size: 30ox;">
        <div class="manualFrame">
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>每月模式，例：薪資支出、辦公室租金</p>
                  <p>1. 進入「應收付資料」功能</p>
                  <p>2. 按「＋」開啟新增資料視窗</p>
                  <p>3. 選擇「每月模式」後，並輸入其他資料</p>
                  <p>4. 確認新增</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0401" alt="圖片">
          </div>
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container addLine">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>合約模式，例：單筆收支、合約（訂金、期中、驗收）</p>
                  <p>1. 進入「應收付資料」功能</p>
                  <p>2.	按「＋」開啟新增資料視窗</p>
                  <p>3.	選擇「合約模式」</p>
                  <p>4.	按「＋」新增應收付的項目</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0402" alt="圖片">
          </div>
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container addLine">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>5.	輸入資料完成後，確認新增</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0403" alt="圖片">
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="如何訂閱Line通知？" name="5" style="font-size: 30ox;">
        <div class="manualFrame">
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>1.	進入「訂閱Line通知」功能</p>
                  <p>2.	按「＋」開啟新增資料視窗</p>
                  <p>3.	選擇訂閱類型、輸入訂閱人後，送出新增</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0501" alt="圖片">
          </div>
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container addLine">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>4.	點擊上述新增的人員QRCode小圖示</p>
                  <p>5.	打開手機Line APP的掃碼功能，掃描此QRCode</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0502" alt="圖片">
          </div>
          <div class="d-flex flex-lg-row flex-column justify-content-center align-items-center image-container addLine">
            <div class="manualInfo">
              <div style="width: 100%;">
                <ol class="text-start">
                  <p>6.	點擊掃描的url網址結果</p>
                  <p>7.	選擇「透過1對1聊天接收LINE Notify的通知」</p>
                  <p>8.	看見綁定成功後，即可收到當天的應收付通知</p>
                </ol>
              </div>
            </div>
            <img :src="bossCarouse0503" alt="圖片">
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style>
  .el-collapse {
    --el-collapse-border-color: #fff !important;
  }

  .el-collapse-item__header {
    font-size: 15px !important;
    margin-bottom: 1rem;
    border-radius: 25px !important;
    padding: 0 2rem;
    background-color: #42a2a1 !important;
    color: #fff !important;
  }

  .el-collapse-item__header.is-active  {
    background-color: #fcb042 !important;
  }

  .image-container img {
    width: 45%;
  }

  .image-container div {
    width: 45%;
    height: 100%;
  }

  @media (max-width: 991px) {
    .image-container img {
      width: 70%;
    }
  }
</style>

<script>
import { ref } from 'vue';
import BossCarousel01 from '@/assets/images/carousel_01.jpg';
import BossCarousel0201 from '@/assets/images/carousel_0201.jpg';
import BossCarousel0202 from '@/assets/images/carousel_0202.jpg';
import BossCarousel03 from '@/assets/images/carousel_03.jpg';
import BossCarousel0401 from '@/assets/images/carousel_0401.jpg';
import BossCarousel0402 from '@/assets/images/carousel_0402.jpg';
import BossCarousel0403 from '@/assets/images/carousel_0403.jpg';
import BossCarousel0501 from '@/assets/images/carousel_0501.jpg';
import BossCarousel0502 from '@/assets/images/carousel_0502.jpg';
import BossCarousel0503 from '@/assets/images/carousel_0503.jpg';

export default {
  setup() {
    const activeNames = ref(['0'])
    return {
      activeNames
    };
  },
  data() {
    return {
      bossCarouse01: BossCarousel01,
      bossCarouse0201: BossCarousel0201,
      bossCarouse0202: BossCarousel0202,
      bossCarouse03: BossCarousel03,
      bossCarouse0401: BossCarousel0401,
      bossCarouse0402: BossCarousel0402,
      bossCarouse0403: BossCarousel0403,
      bossCarouse0501: BossCarousel0501,
      bossCarouse0502: BossCarousel0502,
      bossCarouse0503: BossCarousel0503,
    };
  },
};
</script>
