<template>
  <BossSecurity v-if="policyId === 'Security'"/>
  <BossPrivacy v-if="policyId === 'Privacy'"/>
  <BossTerms v-if="policyId === 'Terms'"/>
</template>

<script>
  import BossSecurity from '@/components/share/BossSecurity.vue';
  import BossPrivacy from '@/components/share/BossPrivacy.vue';
  import BossTerms from '@/components/share/BossTerms.vue';

  export default {
    components: {
      BossSecurity,
      BossPrivacy,
      BossTerms
    },
    data() {
      return {
        policyId: null,
      };
    },
    mounted() {
      this.policyId = this.$route.params.id;
    },
    beforeRouteUpdate(to, from, next) {
      this.policyId = to.params.id;
      next();
    }
  };
</script>